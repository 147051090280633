<template>
    <div>
        <u-tit-wrap-x-small v-bind="$attrs.headerProps">
            <template #titleContents> {{ term }} <span v-if="required" class="red--text">*</span> </template>
        </u-tit-wrap-x-small>
        <slot />
    </div>
</template>

<script>
import UTitWrapXSmall from "@/components/publish/styles/typography/u-tit-wrap-x-small.vue";

export default {
    components: {
        UTitWrapXSmall,
    },
    props: {
        term: String,
        required: Boolean,
    },
};
</script>
